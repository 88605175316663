import React from 'react';
import { callendarGrey } from '../../../../../assets/images/icons';
import { Button, Container } from 'mdbreact';
import DeliveryDatepickerContainer from '../../../../Datepicker/DeliveryDatepickerContainer';
import FormattedMessageWithClass from '../../../../Utils/FormattedMessageWithClass';
import FiltersContainer from '../Filters/FiltersContainer';
import SearchContainer from '../Search/SearchContainer';
import ProductSliderContainer from '../../../../ProductSlider/ProductSliderContainer';
import { FormattedMessage } from 'react-intl';
import DownloadAssortmentButton from '../../../../Table/components/DownloadAssortmentButton';
import ViewModeButton from '../../../../Table/components/ViewModeButton';

const MasterTableHeader = ({
    openDeliveryDatepicker,
    handleDownloadAssortment,
    loadingDownloadAssortment,
    handleDownloadAssortmentExcel,
    loadingDownloadAssortmentExcel,
    handleProductTypeSelect,
    showMyListButton,
    currentViewMode,
    handleViewMode,
    isFairActive,
    campaignStickers,
}) => (
    <Container fluid className="full-width">
        <div className="master-table-header">
            <Container fluid className="row ml-0 pl-0">
                <div className="col">
                    <div className="master-table-header__datepicker-wrapper">
                        <div className="master-table-header__datepicker-wrapper__label-container">
                            <img
                                onClick={openDeliveryDatepicker}
                                src={callendarGrey}
                                className="callendar icon"
                                alt="callendar ico"
                            />
                            <FormattedMessageWithClass
                                className="master-table-header__label"
                                id={'table.delivery_datepicker.label'}
                            />
                        </div>
                        <div className="master-table-header__datepicker">
                            <DeliveryDatepickerContainer />
                        </div>
                    </div>
                </div>
                {isFairActive && (
                    <div className="ml-4 exhibition-wrapper">
                        <Button
                            className="btn btn-red"
                            onClick={() => (document.location = '/fair')}
                        >
                            <FormattedMessage id="menu.exhibition_module" />
                        </Button>
                    </div>
                )}
                <div className="master-table-header__container-stickers">
                    {campaignStickers}
                </div>
                {/*<div>
                    <ProductSliderContainer />
                </div>*/}
                {/*<div>
                    <SearchContainer withoutCategories={true} />
                </div>
                <div>
                    <FiltersContainer />
                </div>*/}
            </Container>
        </div>
    </Container>
);

export default MasterTableHeader;
