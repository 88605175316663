import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import TitleHeader from '../../components/TitleHeader';
import recipesGrey from '../../../../assets/images/recipes-grey.svg';
import ItemBox from '../../components/ItemBox/ItemBox';
import MasonryInfiniteScroller from 'react-masonry-infinite';
import { Spinner } from 'mdbreact';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import SearchContainer from './SearchContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
import history from '../../../../history';

const RecipesList = ({
    category,
    recipes,
    hasMore,
    isLoading,
    loadMore,
    toggleFavourites,
    title,
    titleValues,
    withSearch,
    onReSearch,
}) => {
    useEffect(() => {
        loadMore();
    }, []);

    const goToCategories = () => {
        history.push('/recipes/categories');
    };

    return (
        <div
            className={`recipes recipes__list ${
                withSearch ? 'recipes__list--with-search' : ''
            }`}
        >
            <TitleHeader
                icon={recipesGrey}
                title="recipes.categories.title"
                className="left-align-header"
            >
                <SearchContainer
                    isWithIcon={true}
                    onReSearch={onReSearch}
                    customClearAction={goToCategories}
                />

                {/* <p className="mt-4">
                    <Link to="/recipes/favourites" className="recipes__list__favourites">
                        <i className="favourite-icon__icon favourite-icon--is-favourite"/>
                        <FormattedMessage id="recipes.favourites_url"/>
                    </Link>
                </p> */}
            </TitleHeader>

            <Link to="/recipes/categories" className="recipes__list__go-back">
                <FormattedMessage id="recipes.list.back_url" />
            </Link>
            <InfiniteScroll
                hasMore={!isLoading && hasMore}
                loadMore={loadMore}
                className="recipes__grid"
                dataLength={recipes.length}
            >
                {recipes.map((recipe) => (
                    <ItemBox
                        key={recipe.id}
                        id={recipe.id}
                        image={recipe.image}
                        text={recipe.title}
                        link={`/recipe/${recipe.slug}`}
                        isFavourite={recipe.isFavourite}
                        toggleFavourites={toggleFavourites}
                    />
                ))}
            </InfiniteScroll>

            {isLoading && (
                <div className="flex-center">
                    {' '}
                    <Spinner className="brand-color" />
                </div>
            )}
        </div>
    );
};

RecipesList.propTypes = {
    category: PropTypes.string,
    hasMore: PropTypes.bool,
    isLoading: PropTypes.any,
    loadMore: PropTypes.func,
    recipes: PropTypes.array,
    title: PropTypes.string,
    titleValues: PropTypes.object,
    toggleFavourites: PropTypes.func,
};

RecipesList.defaultProps = {
    title: 'recipes.list.title',
    titleValues: { category: '' },
    withSearch: false,
};

export default RecipesList;
