import React from 'react';

const ToggleSwitch = ({ value, onChange, title, count = null }) => {
    return (
        <div style={styles.container}>
            <p style={styles.label}>
                {title}
                {!!count && <span style={styles.count}>({count})</span>}
            </p>

            <div
                style={{
                    ...styles.switch,
                    backgroundColor: value ? '#CBE4AD' : '#F2F2F2',
                }}
                onClick={() => onChange(!value)}
            >
                <div
                    style={{
                        ...styles.knob,
                        transform: value
                            ? 'translateX(26px)'
                            : 'translateX(4px)',
                        backgroundColor: value ? '#5CAA00' : '#C3C3C3',
                    }}
                />
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        fontFamily: 'General Sans, sans-serif',
        marginBottom: '10px',
        color: '#000',
        justifyContent: 'space-between',
    },
    label: {
        fontWeight: '600',
        marginBottom: '0',
    },
    count: {
        color: 'gray',
        fontSize: '14px',
        fontWeight: '400',
        marginLeft: '5px',
    },
    switch: {
        position: 'relative',
        minWidth: '50px',
        height: '26px',
        borderRadius: '20px',
        cursor: 'pointer',
        transition: 'background 0.3s',
        display: 'flex',
        alignItems: 'center',
        padding: '2px',
    },
    knob: {
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        transition: 'transform 0.3s, background 0.3s',
    },
};

export default ToggleSwitch;
