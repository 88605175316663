import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '../../Table/Table';
import NotificationContainer from '../../Notification/NotificationContainer';
import TableHeader from '../../Table/components/TableHeader';
import MasterTableHeader from './components/MasterTableHeader/MasterTableHeader';
import { FormattedMessage, injectIntl } from 'react-intl';
import SearchContainer from './components/Search/SearchContainer';
import TopProductContainer from '../../TopProduct/TopProductContainer';
import BoxProductContainer from '../../BoxProduct/BoxProductContainer';
import CampaignStickers from '../../CampaignPoints/CampaignStickers';
import TablePreferences from '../../Table/components/TablePreferences';
import PreferencesPhoto from '../../Table/components/PreferencesPhoto';
import TagInfoContainer from './components/TagInfo/TagInfoContainer';
import ViewModeButton from '../../Table/components/ViewModeButton';
import Categories from '../../Categories';
import { useRef } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchProducts } from '../../../actions/masterTable';
import {
    fetchProductCategories,
    fetchProductSuppliers,
} from '../../../actions/routines';

const MasterTable = ({
    data,
    columns,
    //pages,
    loading,
    itemsCount,
    myItemsCount,
    myFavoriteItemsCount,
    selectedProductType,
    isFilterable,
    toggleFilterable,
    handleToggleColumn,
    handleFetchData,
    handleProductTypeSelect,
    openDeliveryDatepicker,
    handleDownloadAssortment,
    loadingDownloadAssortment,
    handleDownloadAssortmentExcel,
    loadingDownloadAssortmentExcel,
    serviceNotifications,
    showOnlyMyList,
    showMyListButton,
    isFairActive,
    showKSListButton,
    showMyFavourite,
    showMaSupplier,
    currentViewMode,
    handleViewMode,
    hasMore,
    currentPage,
    location,
    fetchProducts,
    handlerSelectedFilters,
    showTopListContainer,
    needReloadCategories,
    selectedPromotionType,
    handlePromotionTypeSelect,
    itemsWithPromotion,
    itemsWithoutPromotion,
    currentTotal,
    intl,
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [hideViewSelect, setHideViewSelect] = useState(false);
    const widthRef = useRef(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            if (widthRef.current !== window.innerWidth) {
                widthRef.current = window.innerWidth;
                setWindowWidth(window.innerWidth); // Update state to trigger re-render
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth < 990) {
            handleViewMode('box');
            setHideViewSelect(true);
        } else {
            setHideViewSelect(false);
        }
    }, [windowWidth, handleViewMode]);

    const getPromotionText = (selectedProductType) => {
        switch (selectedProductType) {
            case 0: {
                return `${intl.formatMessage(
                    {
                        id: 'table.product_counter.label',
                    },
                    { count: itemsCount }
                )}`;
            }
            case 1: {
                return `${intl.formatMessage(
                    {
                        id: 'table.my_order_list_download',
                    },
                    { count: myItemsCount }
                )}`;
            }
            case 3: {
                return `${intl.formatMessage(
                    {
                        id: 'table.favourite.label',
                    },
                    { count: myFavoriteItemsCount }
                )}`;
            }
            default: {
                return '';
            }
        }
    };

    return (
        <div className="master-table-container">
            <div className="row">
                <div className="col-md-12">
                    <MasterTableHeader
                        isFairActive={isFairActive}
                        openDeliveryDatepicker={openDeliveryDatepicker}
                        handleDownloadAssortment={handleDownloadAssortment}
                        loadingDownloadAssortment={loadingDownloadAssortment}
                        handleDownloadAssortmentExcel={
                            handleDownloadAssortmentExcel
                        }
                        loadingDownloadAssortmentExcel={
                            loadingDownloadAssortmentExcel
                        }
                        handleProductTypeSelect={handleProductTypeSelect}
                        showMyListButton={showMyListButton}
                        currentViewMode={currentViewMode}
                        handleViewMode={handleViewMode}
                        campaignStickers={
                            <CampaignStickers shape="rectangle" />
                        }
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {serviceNotifications &&
                        serviceNotifications.map((notification) => {
                            return (
                                <NotificationContainer
                                    key={notification.id}
                                    notificationId={notification.id}
                                    type="inline"
                                    title={
                                        <FormattedMessage
                                            id={'notifications.type.service'}
                                        />
                                    }
                                    text={notification.notification}
                                    show={true}
                                    url={notification.link}
                                />
                            );
                        })}
                </div>
            </div>
            <div className="row">
                <div className="col-12">&nbsp;</div>
            </div>
            <div className="row m-0 container-max-width">
                <div className="col-lg-2 m-0 p-0">
                    <SearchContainer
                        withFilters={true}
                        handleProductTypeSelect={handleProductTypeSelect}
                        productSelectedType={selectedProductType}
                        productsCount={itemsCount}
                        myProductsCount={myItemsCount}
                        myFavoriteProductsCount={myFavoriteItemsCount}
                        loading={loading}
                        showOnlyMyList={showOnlyMyList}
                        showMyListButton={showMyListButton}
                        myListLabel="table.my_order_list_download"
                        allProductsLabel="table.product_counter.label"
                        showKSListButton={showKSListButton}
                        showMyFavourite={showMyFavourite}
                        showMaSupplier={showMaSupplier}
                        handlerSelectedFilters={handlerSelectedFilters}
                        promotionListLabel="table.promotion_filter.label"
                        selectedPromotionType={selectedPromotionType}
                        handlePromotionTypeSelect={handlePromotionTypeSelect}
                        itemsWithPromotion={itemsWithPromotion}
                        itemsWithoutPromotion={itemsWithoutPromotion}
                        isFairActive={isFairActive}
                        handleDownloadAssortment={handleDownloadAssortment}
                        loadingDownloadAssortment={loadingDownloadAssortment}
                        handleDownloadAssortmentExcel={
                            handleDownloadAssortmentExcel
                        }
                        loadingDownloadAssortmentExcel={
                            loadingDownloadAssortmentExcel
                        }
                    />
                </div>
                <div className="col-md-10 pr-0 mb-auto mobile-items-container">
                    <div
                        className="row"
                        style={{
                            marginRight: 0,
                        }}
                    >
                        <div
                            className="col"
                            style={{
                                paddingRight: 0,
                            }}
                        >
                            {showTopListContainer && <TopProductContainer />}
                        </div>
                    </div>

                    <div className="row">
                        <Categories
                            fetchProducts={fetchProducts}
                            data={data}
                            location={location}
                            needReloadCategories={needReloadCategories}
                        />
                    </div>

                    <TagInfoContainer />

                    <div
                        className="row"
                        style={{
                            marginRight: 0,
                            marginLeft: 0,
                        }}
                    >
                        <div className="col pr-0 table-box-items-container pl-0">
                            <div
                                className={`view-mode-container ${
                                    currentViewMode === 'table' ? 'list' : ''
                                }`}
                            >
                                <div className="promotion-table-header">
                                    <p className="promotion-title">
                                        {getPromotionText(selectedProductType)}
                                    </p>
                                </div>
                                <div className="d-flex table-pref-container">
                                    {currentViewMode === 'table' && (
                                        <div className="col pl-0 m-0 with-image-checkbox">
                                            <PreferencesPhoto
                                                columns={columns}
                                                handleToggleColumn={
                                                    handleToggleColumn
                                                }
                                            />
                                        </div>
                                    )}
                                    {!hideViewSelect && (
                                        <div className="view-mode-wrapper bottom-type mb-0 d-center align-items-center">
                                            {currentViewMode === 'table' && (
                                                <TablePreferences
                                                    columns={columns}
                                                    handleToggleColumn={
                                                        handleToggleColumn
                                                    }
                                                />
                                            )}
                                            <ViewModeButton
                                                viewMode="box"
                                                isActive={
                                                    currentViewMode === 'box'
                                                }
                                                handleViewMode={handleViewMode}
                                                size={24}
                                            />
                                            <ViewModeButton
                                                viewMode="table"
                                                isActive={
                                                    currentViewMode === 'table'
                                                }
                                                handleViewMode={handleViewMode}
                                                size={20}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {currentViewMode === 'table' && (
                                <React.Fragment>
                                    <div className="row">
                                        <Table
                                            data={data}
                                            withoutTopFilters={true}
                                            columns={columns}
                                            handleToggleColumn={
                                                handleToggleColumn
                                            }
                                            filterable={isFilterable}
                                            leftSideFilter={true}
                                            manual
                                            onFetchData={(state) =>
                                                handleFetchData(state)
                                            }
                                            /*pages={pages}*/
                                            loading={loading}
                                            itemsCount={currentTotal}
                                            selectedProductType={
                                                selectedProductType
                                            }
                                            withNotification={
                                                serviceNotifications.length !==
                                                0
                                            }
                                            responsibility={true}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                            {currentViewMode === 'box' && (
                                <BoxProductContainer
                                    data={data}
                                    columns={columns}
                                    handleToggleColumn={handleToggleColumn}
                                    manual
                                    onFetchData={(state) =>
                                        handleFetchData(state)
                                    }
                                    loading={loading}
                                    hasMore={hasMore}
                                    currentPage={currentPage}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {/*<TableHeader
                columns={columns}
                toggleFilterable={toggleFilterable}
                handleToggleColumn={handleToggleColumn}
                myListLabel="table.my_order_list_download"
                allItemsLabel="table.product_counter.label"
                keurslagerItemsLabel="table.keurslager_list.label"
                handleDownloadAssortment={handleDownloadAssortment}
                loadingDownloadAssortment={loadingDownloadAssortment}
                handleDownloadAssortmentExcel={handleDownloadAssortmentExcel}
                loadingDownloadAssortmentExcel={loadingDownloadAssortmentExcel}
                handleProductTypeSelect={handleProductTypeSelect}
                selectedType={selectedProductType}
                itemsCount={itemsCount}
                loading={loading}
                showOnlyMyList={showOnlyMyList}
                showMyListButton={showMyListButton}
                showKSListButton={showKSListButton}
                isFairActive={isFairActive}
            />*/}
            </div>
        </div>
    );
};

MasterTable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    isFilterable: PropTypes.bool.isRequired,
    toggleFilterable: PropTypes.func.isRequired,
    handleToggleColumn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        loading: state.loadingState.loading,
        categories: state.productCategories.data,
        suppliers: state.productSuppliers.data,
        deliveryDate: state.deliveryDatepicker.deliveryDate,
    };
};

export default injectIntl(
    connect(mapStateToProps, {
        fetchProducts,
        fetchProductCategories,
        fetchProductSuppliers,
    })(MasterTable)
);
