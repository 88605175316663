import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { closeGrey } from '../../assets/images/icons';
import swal from 'sweetalert2';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { injectIntl } from 'react-intl';
import {
    hideFilterable,
    openFilterable,
    toggleFilterable,
} from '../../actions/table';
import * as R from 'ramda';
import qs from 'qs';
import history from '../../history';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function Table(props) {
    const {
        data,
        className,
        onClick,
        columns,
        filterable,
        intl,
        showPaginationBottom,
        showProductDetails,
        showPageSizeOptions,
        addHoverClassOnColumn,
        loading,
        highlight,
        deliveryDate,
        resolveData,
        hideHead,
        hideBody,
        isFairFixed,
        responsibility,
        responsibilityMaxWidth,
        SubComponent,
        pageSize: propPageSize,
        withoutTopFilters,
        isFairTable,
        ...rest
    } = props;

    const [headerWidth, setHeaderWidth] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);
    const [fixedHeader, setFixedHeader] = useState(false);
    const [fixedSearch, setFixedSearch] = useState(false);
    const [fixedSearchAdditional, setFixedSearchAdditional] = useState(false);
    const [itemsCount, setItemsCount] = useState(0);
    const [originalPageSize] = useState(props.pageSize || 50);
    const [pageSize, setPageSize] = useState(props.pageSize || 50);
    const [pages, setPages] = useState(null);
    const [forceCloseFiltering, setForceCloseFiltering] = useState(
        props.leftSideFilter
    );
    const [filtered, setFiltered] = useState(getFiltersInQueryParams());
    const [lastRequestParams, setLastRequestParams] = useState({});
    const [tableInitTop, setTableInitTop] = useState(0);
    const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
    const [defaultResponsibilityMaxWidth] = useState(1024);
    const isRenderedSearchHeader = useRef(false);

    const wrapperRef = useRef();

    const handleScroll = useCallback(() => {
        const node = wrapperRef.current;

        if (!props.withoutTopFilters) {
            let searchBlockTop = props.withNotification ? 490 : 400;

            if (document.querySelector('#main-search')?.closest('.delivery')) {
                searchBlockTop = 400;
            }

            if (
                document.querySelector('#main-search')?.closest('.promotions')
            ) {
                searchBlockTop = 340;
            }

            if (node.getBoundingClientRect().top) {
                if (node.getBoundingClientRect().top < searchBlockTop) {
                    !fixedSearch && setFixedSearch(true);
                } else {
                    fixedSearch && setFixedSearch(false);
                }

                if (node.getBoundingClientRect().top < 85 && fixedSearch) {
                    !fixedSearchAdditional && setFixedSearchAdditional(true);
                } else {
                    fixedSearchAdditional && setFixedSearchAdditional(false);
                }
            }
        }

        if (!tableInitTop) {
            setTableInitTop(node.getBoundingClientRect().top + window.scrollY);
        }

        if (node.getBoundingClientRect().top < 83 && props.withStickyHeader) {
            if (!fixedHeader) {
                setFixedHeader(true);
            }
            setHeaderTop(window.scrollY + 83 - tableInitTop);
        } else {
            if (fixedHeader) {
                setFixedHeader(false);
            }
            setHeaderTop(0);
        }
    }, [
        props.withoutTopFilters,
        props.withNotification,
        props.withStickyHeader,
        wrapperRef,
        fixedSearch,
        fixedSearchAdditional,
        fixedHeader,
        tableInitTop,
    ]);

    useEffect(() => {
        if (!props.itemsCount && data) {
            // FIX FOR OTHER TABLES WHERE THERE IS NO API CALL YET
            setPages(Math.ceil(data.length / pageSize));
            return;
        }

        if (props.itemsCount) {
            setPages(Math.ceil(props.itemsCount / pageSize));
            setPageSize(
                originalPageSize < props.itemsCount
                    ? originalPageSize
                    : props.itemsCount
            );
        }
    }, [props.itemsCount, data, pageSize, originalPageSize]);

    const handleResize = useCallback(() => {
        setWindowInnerWidth(window.innerWidth);
    }, []);

    const addFilterClearFeature = useCallback(() => {
        isRenderedSearchHeader.current = true;

        Object.assign(ReactTableDefaults.column, {
            Filter: (cell) => (
                <React.Fragment>
                    <input
                        type="text"
                        value={cell.filter ? cell.filter.value : ''}
                        onChange={(e) => cell.onChange(e.target.value)}
                        placeholder={
                            cell.column.filterPlaceholder
                                ? props.intl.formatMessage({
                                      id: cell.column.filterPlaceholder,
                                  })
                                : ''
                        }
                    />
                    <span
                        className="filter-clear"
                        onClick={() => {
                            cell.onChange('');
                            cell.filter = undefined;
                        }}
                    >
                        <img src={closeGrey} alt="clear" />
                    </span>
                </React.Fragment>
            ),
        });
    }, [props.intl]);

    const initFiltersActionRequestModal = useCallback(() => {
        if (filtered.length > 0) {
            const { intl } = props;

            const swalWithBootstrapButtons = swal.mixin({
                confirmButtonClass: 'btn btn-brand btn-sm btn-flat',
                cancelButtonClass: 'btn btn-grey btn-sm btn-flat',
                buttonsStyling: false,
            });

            swalWithBootstrapButtons({
                title: intl.formatMessage({
                    id: 'table.filters.action_modal.title',
                }),
                showCancelButton: true,
                confirmButtonText: intl.formatMessage({
                    id: 'table.filters.action_modal.keep',
                }),
                cancelButtonText: intl.formatMessage({
                    id: 'table.filters.action_modal.reset',
                }),
                reverseButtons: true,
            }).then((result) => {
                if (!result.value) {
                    setFiltered([]);
                }
            });
        }
    }, [filtered, props, setFiltered]);

    const appendFiltersHeader = useCallback(() => {
        if (document.getElementsByClassName('filters-title').length > 0) {
            return;
        }

        const { intl } = props;
        const filtersDiv = document.getElementsByClassName('-filters');
        const filtersChild = document.querySelector('.-filters .rt-tr');
        const title = document.createElement('p');

        const closeBtn = document.createElement('span');
        closeBtn.className = 'filters-lnk';
        closeBtn.textContent = intl.formatMessage({
            id: 'product.close_filter',
        });
        closeBtn.onclick = () => toggleFilterable();

        if (!props.isFairTable) {
            title.appendChild(closeBtn);
        }

        title.className = 'filters-title';
        title.onclick = () => {
            initFiltersActionRequestModal();
            setForceCloseFiltering(true);
        };

        filtersDiv[0].insertBefore(title, filtersChild);
    }, [props.intl, props.toggleFilterable, props.isFairTable]);

    useEffect(() => {
        if (props.withStickyHeader) {
            document.addEventListener('scroll', handleScroll);
        }

        if (forceCloseFiltering) {
            hideFilterable();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            document.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [
        props.withStickyHeader,
        forceCloseFiltering,
        handleScroll,
        handleResize,
        props,
    ]);

    useEffect(() => {
        handleScroll();

        if (props.filterable) {
            addFilterClearFeature();
            appendFiltersHeader();
        }
    }, [props.filterable, addFilterClearFeature, appendFiltersHeader]);

    useEffect(() => {
        if (props.itemsCount !== undefined && props.itemsCount !== itemsCount) {
            const newPageSize =
                originalPageSize < props.itemsCount
                    ? originalPageSize
                    : props.itemsCount;
            setPageSize(newPageSize);
        }
    }, [props.itemsCount, itemsCount, originalPageSize]);

    function getFiltersInQueryParams() {
        // Логіка отримання фільтрів із query-параметрів
        return {};
    }

    const getFilterableColumns = () => {
        return R.filter(R.propEq('filterable', undefined), props.columns);
    };

    const onFetchData = (state) => {
        if (!props.manual) {
            // Skip processing if manual mode is disabled
            return;
        }

        const queryParams = qs.parse(history.location.search.slice(1)); // Parse current query parameters
        const queryParamsOriginal = { ...queryParams }; // Store for comparison

        const params = {
            page: (++state.page).toString(),
            limit: props.originalPageSize, // Replace with appropriate prop if needed
        };

        if (state.sorted.length) {
            params.sort = `${state.sorted[0].id},${
                state.sorted[0].desc ? 'DESC' : 'ASC'
            }`;
        }

        if (state.filtered.length) {
            if (!state.forceCloseFiltering) {
                openFilterable(); // Open filterable UI if required
            }

            state.filtered.forEach((filter) => {
                params[`s_${filter.id}`] = filter.value;
            });

            params.filtered = '1';
            setFiltered(state.filtered);
        } else {
            params.filtered = '0';
        }

        // Clear all search filters
        getFilterableColumns().forEach((column) => {
            if (queryParams[`s_${column.id}`]) {
                delete queryParams[`s_${column.id}`];
            }
        });

        params.mylist = props.selectedProductType === 1 ? 1 : 0;
        params.kslist = props.selectedProductType === 2 ? 1 : 0;

        const newParams = { ...queryParams, ...params };
        const { limit, ...newParamsWithoutLimit } = newParams;

        if (
            !R.equals(newParamsWithoutLimit, queryParamsOriginal) &&
            !R.equals(lastRequestParams, newParams)
        ) {
            setLastRequestParams(newParams);
            props.onFetchData(newParams); // Trigger fetch data from props
        }

        history.push({
            ...history,
            search: qs.stringify(newParamsWithoutLimit),
        });

        if (newParams.s_all !== undefined) {
            scrollToTableTop();
        }
    };

    const handleClick = useCallback(
        (e, state, rowInfo, column, handleOriginal) => {
            // Replace this with your click handler logic
            if (handleOriginal) handleOriginal();
        },
        [] // Dependencies array
    );

    const handlePageSizeChange = useCallback((size) => setPageSize(size), []);

    const handleFilteredChange = useCallback(
        (newFiltered) => setFiltered(newFiltered),
        []
    );

    const handleMouseOver = useCallback(
        (columnId) => {
            if (addHoverClassOnColumn) addHoverClassOnColumn(columnId);
        },
        [addHoverClassOnColumn]
    );

    const handleMouseLeave = useCallback(() => {
        if (addHoverClassOnColumn) addHoverClassOnColumn(null);
    }, [addHoverClassOnColumn]);

    const scrollToTableTop = () => {
        if (wrapperRef.current) {
            wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div
            ref={wrapperRef}
            className={`table table-preferences ${
                loading ? 'table--loading' : ''
            } ${hideHead ? 'table--headHidden' : ''} ${
                hideBody ? 'table--bodyHidden' : ''
            } ${className}`}
            onClick={onClick}
        >
            <ReactTableFixedColumns
                {...rest}
                minRows={0}
                data={data}
                columns={columns}
                SubComponent={
                    !responsibility ||
                    windowInnerWidth > (responsibilityMaxWidth ?? 1024)
                        ? SubComponent || null
                        : null // Replace with a fallback SubComponent logic if needed
                }
                pageSize={pageSize}
                pages={pages}
                className={`table__rt ${
                    responsibility ? 'responsive-table' : ''
                } ${highlight ? '-highlight' : ''} -striped ${
                    fixedHeader
                        ? isFairFixed
                            ? 'table__rt--fixed-header-cart'
                            : 'table__rt--fixed-header'
                        : ''
                } ${isFairTable ? '-fair-table' : ''} ${
                    withoutTopFilters ? 'without-top-filters' : ''
                }`}
                filterable={filterable}
                filtered={filtered}
                resizable={false}
                previousText={intl.formatMessage({
                    id: 'table.footer.prev_text',
                })}
                nextText={intl.formatMessage({
                    id: 'table.footer.next_text',
                })}
                pageText={intl.formatMessage({
                    id: 'table.footer.page_text',
                })}
                ofText={intl.formatMessage({ id: 'table.footer.of_text' })}
                noDataText={intl.formatMessage({
                    id: 'table.no_data_text',
                })}
                rowsText={intl.formatMessage({
                    id: 'table.footer.rows_text',
                })}
                showPaginationBottom={showPaginationBottom}
                showPageSizeOptions={showPageSizeOptions}
                onFetchData={onFetchData}
                collapseOnDataChange={false}
                getTheadProps={() => ({
                    style: headerWidth > 0 ? { width: headerWidth } : {},
                })}
                getTdProps={(state, rowInfo, column) => ({
                    onClick: (e, handleOriginal) =>
                        handleClick(e, state, rowInfo, column, handleOriginal),
                    onMouseOver: () => handleMouseOver(column.id),
                    onMouseLeave: handleMouseLeave,
                })}
                getThProps={() => ({
                    onMouseLeave: handleMouseLeave,
                })}
                onPageChange={scrollToTableTop}
                onPageSizeChange={handlePageSizeChange}
                onFilteredChange={handleFilteredChange}
                resolveData={resolveData}
            />
        </div>
    );
}

export default injectIntl(Table);
