import { useState, useRef } from 'react';

const SearchInput = ({
    placeholder,
    onChange,
    value,
    type,
    className,
    currentSetSearch = '',
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);

    const handleClear = () => {
        onChange({
            target: { value: '' },
        }); // Clear input value
    };

    return (
        <>
            <input
                onChange={onChange}
                value={value}
                type={type}
                className={`${className} ${
                    currentSetSearch && value ? 'focused' : ''
                }`}
                placeholder={placeholder}
                ref={inputRef}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
            {currentSetSearch && value && (
                <button
                    className="common-search-filter__clear"
                    onClick={handleClear}
                />
            )}
        </>
    );
};

export default SearchInput;
