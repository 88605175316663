import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable */
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeDeliveryDatepicker, setDeliveryDate } from '../../actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createOrUpdateActiveCart } from '../../actions/routines';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/nl';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import debounce from 'lodash.debounce';

class DeliveryDatepicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            picker: null,
        };

        const styles = {
            overrides: {
                MuiBackdrop: {
                    root: {
                        backgroundColor: 'white',
                    },
                },
                MuiPaper: {
                    elevation24: {
                        boxShadow: 'none',
                    },
                },
                MuiPickersToolbar: {
                    toolbar: {
                        backgroundColor: '#3EA400',
                    },
                },
                MuiInput: {
                    input: {
                        cursor: 'pointer',
                        // textDecoration: 'underline',
                    },
                },
                MuiPickersDay: {
                    current: {
                        color: '#FFF',
                        fontWeight: 'normal',
                    },
                    day: {
                        backgroundColor: '#3EA400',
                        color: '#FFF',
                        margin: '5px',
                        height: '31px',
                        width: '31px',
                        '&:hover': {
                            backgroundColor: '#3EA400',
                        },
                        '&:active': {
                            backgroundColor: '#3EA400',
                        },
                        '&:focus': {
                            backgroundColor: '#3EA400',
                        },
                    },
                    selected: {
                        backgroundColor: '#2c6f02 !important',
                        '&:hover': {
                            backgroundColor: '#2c6f02 !important',
                        },
                    },
                    disabled: {
                        backgroundColor: 'transparent',
                    },
                },
                MuiButton: {
                    textPrimary: {
                        color: '#000',
                    },
                },
            },
        };

        if (this.props.global === true) {
            const hideInput = {
                MuiFormControl: {
                    root: {
                        display: 'none',
                    },
                },
            };
            styles.overrides = Object.assign(hideInput, styles.overrides);
        }

        this.greenTheme = createMuiTheme(styles);
    }

    setRef = (node) => {
        this.picker = node;
    };

    componentDidMount() {
        moment.locale(this.props.intl.locale);
    }

    componentDidUpdate(prevProps) {
        if (
            !prevProps.isOpen &&
            this.props.isOpen === true &&
            this.props.global === true
        ) {
            this.picker.open();
        }
    }

    handleDateChange = debounce((date) => {
        this.props.createOrUpdateActiveCart(moment(date));

        this.props.setDeliveryDate(moment(date).format());
    }, 500);

    handleClose = () => {
        this.props.closeDeliveryDatepicker();
    };

    render() {
        const { enabledDates, global, isOpen, intl, cart } = this.props;

        //until deliveryDates have been loaded, set the date to today
        let deliveryDate = moment();

        if (cart && cart.delivery_date) {
            deliveryDate = moment(cart.delivery_date);
        }

        return (
            <div>
                {global && isOpen && (
                    <p className="delivery-datepicker__title--global">
                        <FormattedMessage id="delivery_datepicker.global_title" />
                    </p>
                )}
                <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    locale={intl.locale}
                >
                    <MuiThemeProvider theme={this.greenTheme}>
                        <DatePicker
                            ref={this.setRef}
                            format="dddd DD MMMM YYYY"
                            value={deliveryDate}
                            disablePast
                            style={{ textTransform: 'uppercase' }}
                            minDate={moment().add(1, 'day')}
                            shouldDisableDate={(date) => {
                                if (
                                    !enabledDates ||
                                    enabledDates.length === 0
                                ) {
                                    return true;
                                }

                                const dateStr = date.format('dddd DD MMMM YY');

                                let contains = enabledDates.some((delivery) => {
                                    return (
                                        moment(delivery.delivery_date).format(
                                            'dddd DD MMMM YY'
                                        ) === dateStr
                                    );
                                });

                                return !contains;
                            }}
                            onChange={this.handleDateChange}
                            onClose={this.handleClose}
                            minDateMessage={intl.formatMessage({
                                id: 'delivery_datepicker.min_date',
                            })}
                        />
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>
            </div>
        );
    }
}

DeliveryDatepicker.propTypes = {
    closeDeliveryDatepicker: PropTypes.func,
    deliveryDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    enabledDates: PropTypes.array,
    global: PropTypes.bool,
    intl: PropTypes.object,
    isOpen: PropTypes.bool,
    setDeliveryDate: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        isOpen: state.deliveryDatepicker.isDeliveryDatepickerOpen,
        deliveryDate: state.deliveryDatepicker.deliveryDate,
        cart: state.cart.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                closeDeliveryDatepicker,
                setDeliveryDate,
                createOrUpdateActiveCart,
            },
            dispatch
        ),
    };
};
DeliveryDatepicker = injectIntl(DeliveryDatepicker);

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDatepicker);
