import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Specification = ({ label, value }) => (
    <div className="product-details__specifications__specification">
        <div>
            <FormattedMessage id={label} />
        </div>{' '}
        <div>
            <strong>{value}</strong>
        </div>
    </div>
);

Specification.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
};

export default Specification;
