import PropTypes from 'prop-types';
import React from 'react';
import TitleHeader from '../components/TitleHeader';
import SearchContainer from './components/SearchContainer';
import ItemBox from '../components/ItemBox/ItemBox';
import {
    downloadArrowWhite,
    downloadArrowGrey,
    folderGrey,
    folderWhite,
} from '../../../assets/images/icons';
import getIconByFileType from '../../../services/fileIconReslover';
import { Spinner } from 'mdbreact';
import Breadcrumbs from './components/Breadcrumbs';
import formatBytes from '../../Utils/formatBytes';
import FileBoxContainer from './components/FileBox/FileBoxContainer';

const Downloads = ({ folderId, items, type, breadcrumbs, isLoading }) => {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    let styles = {};
    if (isIE11) {
        styles = {
            display: 'block',
        };
    }

    return (
        <div className="downloads">
            <TitleHeader
                icon={downloadArrowGrey}
                title="downloads.title"
                className="left-align-header"
            >
                <SearchContainer isWithIcon={true} />
            </TitleHeader>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            {isLoading ? (
                <div className="flex-center mt-5">
                    {' '}
                    <Spinner className="brand-color" width={50} height={50} />
                </div>
            ) : (
                <React.Fragment>
                    <div className="downloads__directories" style={styles}>
                        {!folderId &&
                            type === 'folders' &&
                            items.map((dir) => (
                                <ItemBox
                                    key={dir.id}
                                    image={{ path: folderGrey }}
                                    imageHover={{ path: folderWhite }}
                                    title={dir.name}
                                    text={`(${dir.filesCount})`}
                                    link={`/downloads/${dir.id}`}
                                    isExternalLink={false}
                                />
                            ))}
                    </div>

                    <div className="downloads__files" style={styles}>
                        {(type === 'files' || type === 'search') &&
                            items.map((file, index) => (
                                <FileBoxContainer
                                    index={index}
                                    key={file.id}
                                    id={file.id}
                                    fileImage={file.thumb}
                                    image={getIconByFileType(file.type)}
                                    imageHover={{ path: downloadArrowWhite }}
                                    title={file.name}
                                    pdfViewLink={file.pdfview}
                                    text={`(${formatBytes(file.size)})`}
                                />
                            ))}
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

Downloads.propTypes = {
    folderId: PropTypes.number,
    folders: PropTypes.array,
    files: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default Downloads;
