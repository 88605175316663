import PropTypes from 'prop-types';
import React from 'react';
import Search from '../../../../Search/Search';
import SearchWithFilters from '../../../../Search/SearchWithFilters';
import { injectIntl } from 'react-intl';
import { fetchProducts } from '../../../../../actions/masterTable';
import { connect } from 'react-redux';
import {
    fetchProductCategories,
    fetchProductSuppliers,
} from '../../../../../actions/routines';
import * as qs from 'qs';
import history from '../../../../../history';
import * as R from 'ramda';
import Products from '../../../../../services/api/Products';

class SearchContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            commonSearchValue: null,
            selectedCategoryId: null,
            selectedCategoryValue: null,
            selectedSupplierValue: [],
        };
    }

    async getSelectedValue(selectedCategoryId) {
        function findHighestParentId(category) {
            if (category.parent_category) {
                return findHighestParentId(category.parent_category);
            } else {
                return category;
            }
        }

        const queryParams = qs.parse(history.location.search.slice(1));

        const { data: parents } = await Products.getSubCategories(
            selectedCategoryId,
            queryParams
        );

        const parent = findHighestParentId(parents);

        this.setState({
            selectedCategoryValue: [
                {
                    id: parent.id,
                    value: parent.name,
                    label: parent.name,
                    isParent: true,
                },
            ],
        });
    }

    componentDidMount() {
        if (!this.props.withoutCategories) {
            this.props.fetchProductCategories();
        }

        this.props.fetchProductSuppliers();

        const queryParams = qs.parse(history.location.search.slice(1));
        if (queryParams['s_category']) {
            this.getSelectedValue(queryParams['s_category']);
        }

        if (queryParams['s_all']) {
            this.setState({
                commonSearchValue: queryParams['s_all'],
            });
        }

        if (queryParams['s_suppliers']) {
            this.setState({
                selectedSupplierValue: queryParams['s_suppliers'],
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const queryParams = qs.parse(history.location.search.slice(1));

        if (!R.equals(prevState, this.state)) {
            this.search(queryParams);
        } else {
            if (
                !queryParams['s_suppliers'] &&
                this.state.selectedSupplierValue.length
            ) {
                this.setState({ selectedSupplierValue: [] });
            }
            if (!queryParams['s_all'] && this.state.commonSearchValue) {
                this.setState({ commonSearchValue: '' });
            }
            if (!queryParams['s_category'] && this.state.selectedCategoryId) {
                this.setState({
                    selectedCategoryId: null,
                    selectedCategoryValue: null,
                });
            }
        }

        if (prevProps.suppliers !== this.props.suppliers) {
            if (queryParams['s_suppliers']) {
                const selectedSuppliersRaw = queryParams['s_suppliers'];
                const selected = [];
                this.props.suppliers.forEach((supplier) => {
                    if (selectedSuppliersRaw.includes(supplier?.name)) {
                        selected.push({
                            label: supplier?.name,
                            value: supplier?.name,
                        });
                    }
                });
                this.setState({
                    selectedSupplierValue: selected,
                });
            }
        }

        if (
            queryParams['s_category'] &&
            !R.equals(this.state.selectedCategoryId, queryParams['s_category'])
        ) {
            this.setState({
                selectedCategoryId: queryParams['s_category'],
            });
            this.getSelectedValue(queryParams['s_category']);
        }
    }

    intervalHandler = null;
    search = (params) => {
        if (this.intervalHandler) {
            clearInterval(this.intervalHandler);
        }

        this.intervalHandler = setTimeout(() => {
            const searchCategory = this.state.selectedCategoryValue;
            const searchTerm = this.state.commonSearchValue;
            const searchSupplier = this.state.selectedSupplierValue;
            const queryParams = qs.parse(history.location.search.slice(1));

            if (searchCategory) {
                if (searchCategory[0]?.isParent) {
                    params['s_category'] = queryParams['s_category'];
                } else {
                    params['s_category'] = searchCategory[0]?.id;
                }
            } else {
                delete params['s_category'];
            }

            if (searchTerm) {
                params['s_all'] = searchTerm;
            } else {
                delete params['s_all'];
            }

            if (searchSupplier) {
                const selectedNames = searchSupplier.map(
                    (supplier) => supplier.value
                );
                params['s_suppliers'] = selectedNames;
            } else {
                delete params['s_suppliers'];
            }

            params['page'] = 1;

            history.push({
                ...this.props.location,
                search: qs.stringify(params),
            });

            this.props.fetchProducts({
                deliveryDate: this.props.deliveryDate,
                params: params,
            });

            this.props.handlerSelectedFilters();
        }, 1_000);
    };

    setSelectedCategory = (selectedCategory) => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
        const filtered = selectedCategory.filter(
            (cat) => cat.value !== this.state.selectedCategoryValue?.[0]?.value
        );
        const updateCategories = filtered.map((cat) => {
            const { isParent, ...rest } = cat;
            return rest;
        });

        this.setState({
            selectedCategoryValue: updateCategories,
        });
    };

    setCommonSearch = (e) => {
        this.setState({
            commonSearchValue: e.target.value,
        });
    };

    setSelectedSupplier = (selectedSuppliers) => {
        if (selectedSuppliers.some((e) => e.value === 'all_suppliers')) {
            this.setState({
                selectedSupplierValue: [],
            });
        } else {
            this.setState({
                selectedSupplierValue: selectedSuppliers,
            });
        }
    };

    deleteSelectedSupplier = (name = '') => {
        const filteredArray = this.state.selectedSupplierValue.filter(
            (supplier) => supplier.value !== name
        );
        this.setState({
            selectedSupplierValue: filteredArray,
        });
    };

    render() {
        const {
            intl,
            loading,
            categories,
            withoutCategories,
            withFilters,
            productSelectedType,
            handleProductTypeSelect,
            productsCount,
            myProductsCount,
            myFavoriteProductsCount,
            showOnlyMyList,
            showMyListButton,
            myListLabel,
            allProductsLabel,
            showKSListButton,
            showMyFavourite,
            showMaSupplier,
            suppliers,
            handlerSelectedFilters,
            promotionListLabel,
            selectedPromotionType,
            handlePromotionTypeSelect,
            itemsWithPromotion,
            itemsWithoutPromotion,
            isFairActive,
            handleDownloadAssortment,
            loadingDownloadAssortment,
            handleDownloadAssortmentExcel,
            loadingDownloadAssortmentExcel,
        } = this.props;

        if (withFilters) {
            return (
                <SearchWithFilters
                    withSelect={!withoutCategories}
                    loading={loading}
                    categories={categories}
                    suppliers={suppliers}
                    selectedSupplierValue={this.state.selectedSupplierValue}
                    selectedCategoryValue={this.state.selectedCategoryValue}
                    commonSearchValue={this.state.commonSearchValue}
                    setSelectedCategory={this.setSelectedCategory}
                    setSelectedSupplier={this.setSelectedSupplier}
                    setCommonSearch={this.setCommonSearch}
                    deleteSelectedSupplier={this.deleteSelectedSupplier}
                    placeholder={intl.formatMessage({
                        id: 'table.search.placeholder',
                    })}
                    isAllSuppliersSelected={this.state.isAllSuppliersSelected}
                    searchHandler={this.search}
                    productSelectedType={productSelectedType}
                    handleProductTypeSelect={handleProductTypeSelect}
                    productsCount={productsCount}
                    myProductsCount={myProductsCount}
                    myFavoriteProductsCount={myFavoriteProductsCount}
                    showMyListButton={showMyListButton}
                    myListLabel={myListLabel}
                    showOnlyMyList={showOnlyMyList}
                    allProductsLabel={allProductsLabel}
                    showKSListButton={showKSListButton}
                    showMyFavourite={showMyFavourite}
                    showMaSupplier={showMaSupplier}
                    handlerSelectedFilters={handlerSelectedFilters}
                    promotionListLabel={promotionListLabel}
                    selectedPromotionType={selectedPromotionType}
                    handlePromotionTypeSelect={handlePromotionTypeSelect}
                    itemsWithPromotion={itemsWithPromotion}
                    itemsWithoutPromotion={itemsWithoutPromotion}
                    isFairActive={isFairActive}
                    handleDownloadAssortment={handleDownloadAssortment}
                    loadingDownloadAssortment={loadingDownloadAssortment}
                    handleDownloadAssortmentExcel={
                        handleDownloadAssortmentExcel
                    }
                    loadingDownloadAssortmentExcel={
                        loadingDownloadAssortmentExcel
                    }
                />
            );
        }

        return (
            <Search
                withSelect={!withoutCategories}
                loading={loading}
                options={categories}
                selectedValue={this.state.selectedValue}
                getSelectedValue={this.setSelectedCategory}
                placeholder={intl.formatMessage({
                    id: 'table.search.placeholder',
                })}
                label="delivery.search.btn"
                onSubmit={this.search}
            />
        );
    }
}

SearchContainer.propTypes = {
    intl: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        loading: state.loadingState.loading,
        categories: state.productCategories.data,
        suppliers: state.productSuppliers.data,
        deliveryDate: state.deliveryDatepicker.deliveryDate,
    };
};

export default injectIntl(
    connect(mapStateToProps, {
        fetchProducts,
        fetchProductCategories,
        fetchProductSuppliers,
    })(SearchContainer)
);
