import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'mdbreact';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { find, propEq } from 'ramda';
import placeholderImage from '../../../assets/images/image-placeholder.svg';
import newProductMarkSvg from '../../../assets/images/new-product-mark.svg';
import QuantityContainer from '../../Quantity/QuantityContainer';
import PopoverBadge from '../../Table/components/PopoverBadge';
import OrdersHistory from '../../Table/components/OrdersHistory/OrdersHistory';
import CampaignPointsBadgeContainer from '../../Table/components/CampaignPointsBadge/CampaignPointsBadgeContainer';

const ProductImage = ({ images, name, openProductDetails, id }) => {
    const mainImage = find(propEq('image_type', 0), images);

    return (
        <div
            onClick={() => openProductDetails(id)}
            className="product-novelty__item-box__image-wrapper"
        >
            {mainImage ? (
                <img src={mainImage.url} alt={name} className="img-fluid" />
            ) : (
                <div className="product-novelty__item-box__image-placeholder">
                    <img
                        src={placeholderImage}
                        alt={name}
                        className="img-fluid"
                    />
                </div>
            )}
        </div>
    );
};

const PromotionBadges = ({ promotions, campaigns, id }) => {
    const renderPopoverBadge = (item, type) => (
        <PopoverBadge
            wrapperClassName="product-novelty__item-box__promotion"
            id={id}
            type={type}
            badgeText="i"
            backgroundColor={item.is_future ? 'grey' : item.color || '#D70000'}
            fontColor={item.promotion_type === 1 ? 'darkblue' : 'white'}
            popoverHeader={item.promotion || item.points_label}
            popoverBody={`${item.description || ''}<br><small>${moment(
                item.start_date
            ).format('DD/MM/YYYY')} - ${moment(item.end_date).format(
                'DD/MM/YYYY'
            )}</small>`}
            placement="bottom"
        />
    );

    return (
        <div className="product-novelty__item-box__promotions">
            {promotions.map((promotion) =>
                renderPopoverBadge(promotion, 'promotion')
            )}
            {campaigns.map((campaign) =>
                renderPopoverBadge(campaign, 'campaign')
            )}
        </div>
    );
};

const ProductBox = ({
    product,
    openProductDetails,
    toggleProductFavorite,
    isNoveltyPage = false,
    isTopList = false,
}) => {
    const {
        id,
        name,
        images,
        items_in_package: itemsInPackage,
        order_as_package: orderAsPackage,
        promotions = [],
        campaigns = [],
        amount,
        isNew,
        weeks,
        in_delivery,
        has_orders_provider_error,
        supplier,
        isFavourite,
        article_nr,
        price,
        availableFrom,
        isNoAvailableRefund,
    } = product;

    return (
        <Card className="product-novelty__item-box">
            {isNew && !isNoveltyPage && (
                <img
                    src={newProductMarkSvg}
                    alt="new product"
                    className="product-novelty__item-box__new-product-mark"
                />
            )}

            <div className="favourite-icon">
                <i
                    className={`favourite-icon__icon ${
                        isFavourite ? 'favourite-icon--is-favourite' : ''
                    } ${promotions.length === 0 ? 'favourite-icon--solo' : ''}`}
                    onClick={() => toggleProductFavorite(id)}
                ></i>
            </div>

            <div className="product-novelty__photo__wrapper">
                <ProductImage
                    images={images}
                    name={name}
                    openProductDetails={openProductDetails}
                    id={id}
                />

                <div
                    className="product-novelty__item-box__weeks"
                    style={{ display: 'none' }}
                    onClick={() => openProductDetails(id)}
                >
                    <OrdersHistory
                        weeks={weeks}
                        inDelivery={in_delivery}
                        rowIndex={0}
                        providerError={has_orders_provider_error}
                    />
                </div>
            </div>
            <div className="product-novelty__item-box__separator" />
            <CardBody>
                <div className="product-novelty__item-box__body">
                    <div
                        className={`product-novelty__item-box__body-wrapper ${
                            !isNoAvailableRefund ? 'with-refund' : ''
                        }`}
                    >
                        {isNoAvailableRefund && (
                            <div className="product-novelty__item-box__no-refund-title">
                                <FormattedMessage id="product.no_refund" />
                            </div>
                        )}

                        <div className="product-novelty__item-box__supplier-title">
                            {supplier}
                        </div>

                        <CardTitle
                            onClick={() => openProductDetails(id)}
                            className="product-novelty__item-box__title"
                        >
                            {name}
                        </CardTitle>

                        <div
                            onClick={() => openProductDetails(id)}
                            className="product-novelty__item-box__wrapper-promotions"
                        >
                            {isTopList &&
                                promotions &&
                                promotions.length > 0 && (
                                    <div>
                                        <div
                                            className="product-novelty__item-box__promotion-title"
                                            dangerouslySetInnerHTML={{
                                                __html: promotions[0].promotion,
                                            }}
                                        ></div>
                                        <div className="product-novelty__item-box__promotion-date">
                                            <FormattedMessage id="product_slider.item_box.valid_date" />{' '}
                                            {moment(
                                                promotions[0].start_date
                                            ).format('DD/MM/YYYY')}{' '}
                                            -{' '}
                                            {moment(
                                                promotions[0].end_date
                                            ).format('DD/MM/YYYY')}
                                        </div>
                                    </div>
                                )}
                        </div>

                        <PromotionBadges
                            promotions={promotions}
                            campaigns={campaigns}
                            id={id}
                        />
                    </div>
                    <div>
                        <div className="product-novelty__item-box__separator" />

                        <div className="product-novelty__item-box__article-nr-wraper__container">
                            <div className="product-novelty__item-box__article-nr-wraper">
                                <span className="product-novelty__item-box__article-nr">
                                    <FormattedMessage id="product.product_no" />{' '}
                                    {article_nr}
                                </span>
                                {campaigns && campaigns.length
                                    ? campaigns.map((campaign) => {
                                          return (
                                              <span className="product-novelty__item-box__points-wrapper">
                                                  <CampaignPointsBadgeContainer
                                                      key={campaign.id}
                                                      productId={id}
                                                      campaign={campaign}
                                                  />
                                              </span>
                                          );
                                      })
                                    : ''}
                            </div>

                            {availableFrom && (
                                <div className="product-novelty__item-box__article-nr-wraper">
                                    <span className="product-novelty__item-box__article-nr">
                                        <FormattedMessage id="table.preferences.available" />
                                        : {availableFrom}
                                    </span>
                                </div>
                            )}
                        </div>
                        <Row className="product-novelty__item-box__price-wraper">
                            {price && (
                                <div className="product-novelty__item-box__price card_price">
                                    <span>{price.label.split('/')[0]} </span>
                                    <span className="product-novelty__item-box__price__per">
                                        / {price.label.split('/')[1]}
                                    </span>
                                </div>
                            )}

                            <Col className="product-novelty__item-box__quantity">
                                <QuantityContainer
                                    product={product}
                                    productId={id}
                                    quantity={amount}
                                    itemsInPackage={itemsInPackage}
                                    orderAsPackage={orderAsPackage}
                                    tabIndex={9292929292 + id}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

ProductBox.propTypes = {
    product: PropTypes.object.isRequired,
    openProductDetails: PropTypes.func.isRequired,
    toggleProductFavorite: PropTypes.func.isRequired,
};

export default ProductBox;
