import React from 'react';
import promotionsGrey from '../../../assets/images/promotions-grey.svg';
import TitleHeader from '../components/TitleHeader';
import TableHeader from '../../Table/components/TableHeader';
import Table from '../../Table/Table';
import CampaignStickers from '../../CampaignPoints/CampaignStickers';
import ViewModeButton from '../../Table/components/ViewModeButton';
import PreferencesPhoto from '../../Table/components/PreferencesPhoto';
import TablePreferences from '../../Table/components/TablePreferences';
import BoxProductContainer from '../../BoxProduct/BoxProductContainer';
import SearchContainer from '../Delivery/components/SearchContainer';

const Promotions = ({
    data,
    columns,
    loading,
    itemsCount,
    isFilterable,
    toggleFilterable,
    handleToggleColumn,
    handleFetchData,
    currentViewMode,
    handleViewMode,
    hasMore,
    currentPage,
}) => {
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <TitleHeader
                        icon={promotionsGrey}
                        title="promotions.title"
                        className="left-align-header"
                    >
                        <SearchContainer isWithIcon={true} type="promotions" />
                    </TitleHeader>
                    {/*<TableHeader
                columns={columns}
                toggleFilterable={toggleFilterable}
                handleToggleColumn={handleToggleColumn}
                downloadPdfLabel="promotions.download_pdf_label"
                allItemsLabel="promotions.all_products_label"
            />*/}
                </div>
            </div>
            <div className="row container-max-width-fluid">
                <div className="col-lg-2">
                    <CampaignStickers shape="rectangle" />

                    {/* <SearchContainer
                        withFilters={true}
                        withoutCategories={true}
                        withoutFilterCampaigns={true}
                        withoutFilterAllergens={true}
                        productsCount={itemsCount}
                        loading={loading}
                        myListLabel="table.my_order_list_download"
                        allProductsLabel="table.product_counter.label"
                    /> */}
                </div>
                <div className="col-md-10 promotions-wrapper pr-0">
                    <div
                        className={`view-mode-container ${
                            currentViewMode === 'table' ? 'list' : ''
                        }`}
                    >
                        {/* mocked div for the alignment on the promotions page (space-between) */}
                        <div />
                        {currentViewMode === 'table' && (
                            <div className="col pl-0 m-0 with-image-checkbox">
                                <PreferencesPhoto
                                    columns={columns}
                                    handleToggleColumn={handleToggleColumn}
                                />
                            </div>
                        )}
                        <div className="view-mode-wrapper bottom-type mb-0 d-flex">
                            {currentViewMode === 'table' && (
                                <TablePreferences
                                    columns={columns}
                                    handleToggleColumn={handleToggleColumn}
                                />
                            )}
                            <ViewModeButton
                                viewMode="box"
                                isActive={currentViewMode === 'box'}
                                handleViewMode={handleViewMode}
                                size={24}
                            />
                            <ViewModeButton
                                viewMode="table"
                                isActive={currentViewMode === 'table'}
                                handleViewMode={handleViewMode}
                                size={20}
                            />
                        </div>
                    </div>
                    {currentViewMode === 'table' && (
                        <React.Fragment>
                            <div className="row">
                                <Table
                                    withoutTopFilters={true}
                                    data={data}
                                    columns={columns}
                                    filterable={isFilterable}
                                    loading={loading}
                                    manual
                                    onFetchData={(state) =>
                                        handleFetchData(state)
                                    }
                                    itemsCount={itemsCount}
                                    handleToggleColumn={handleToggleColumn}
                                    responsibility={true}
                                />
                            </div>
                        </React.Fragment>
                    )}

                    {currentViewMode === 'box' && (
                        <div className="row">
                            <div className="col pr-0">
                                <BoxProductContainer
                                    data={data}
                                    columns={columns}
                                    handleToggleColumn={handleToggleColumn}
                                    manual
                                    onFetchData={(state) =>
                                        handleFetchData(state)
                                    }
                                    loading={loading}
                                    hasMore={hasMore}
                                    currentPage={currentPage}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Promotions;
