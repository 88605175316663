import PropTypes from 'prop-types';
import React from 'react';
import Table from '../../Table/Table';
import TitleHeader from '../components/TitleHeader';
import { allergensGrey } from '../../../assets/images/icons';
import Header from './components/Header';

const Allergens = ({
    data,
    columns,
    isFilterable,
    itemsCount,
    loading,
    toggleFilterable,
    addHoverClassOnColumn,
    handleFetchData,
    handleDownloadAllergenListPdf,
    isDownloadLoading,
}) => (
    <div className="allergens">
        <TitleHeader
            title="allergens.title"
            icon={allergensGrey}
            className="left-align-header"
        />
        <Header
            toggleFilterable={toggleFilterable}
            isDownloadLoading={isDownloadLoading}
            handleDownloadAllergenListPdf={handleDownloadAllergenListPdf}
        />
        <Table
            data={data}
            columns={columns}
            defaultPageSize={10}
            filterable={isFilterable}
            addHoverClassOnColumn={addHoverClassOnColumn}
            sortable={false}
            manual
            onFetchData={(state) => handleFetchData(state)}
            loading={loading}
            itemsCount={itemsCount}
            style={{ height: 700 }}
        />
    </div>
);

Allergens.propTypes = {
    addHoverClassOnColumn: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    handleDownloadAllergenListPdf: PropTypes.func,
    handleFetchData: PropTypes.func,
    handleToggleColumn: PropTypes.func.isRequired,
    isDownloadLoading: PropTypes.bool,
    isFilterable: PropTypes.bool.isRequired,
    itemsCount: PropTypes.number,
    loading: PropTypes.bool,
    toggleFilterable: PropTypes.func.isRequired,
};

export default Allergens;
