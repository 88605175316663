import React from 'react';
import PropTypes from 'prop-types';
import WeekColumn from './WeekColumn';
import FutureDeliveries from './FutureDeliveries';

const OrdersHistory = ({
    weeks,
    inDelivery,
    rowIndex,
    providerError,
    isTable = false,
}) => {
    if (providerError) {
        return rowIndex === 0 ? (
            <span>
                History orders <br />
                could not be retrieved.
            </span>
        ) : null;
    }

    return (
        <div
            className={`d-flex flex-row-reverse ${
                isTable ? 'orders-histories' : ''
            }`}
        >
            <div
                className="flex-center"
                style={{ paddingTop: isTable ? '0' : '35px' }}
            >
                {weeks.map((weekData) => (
                    <WeekColumn
                        key={weekData.name}
                        week_name={weekData.name}
                        quantity={weekData.quantity}
                        is_current={weekData.isCurrent}
                    />
                ))}
            </div>
            <FutureDeliveries inDelivery={inDelivery} />
        </div>
    );
};

OrdersHistory.propTypes = {
    weeks: PropTypes.array,
    inDelivery: PropTypes.array,
};

export default OrdersHistory;
