import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    SelectOption,
} from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import NestedOption from '../Pages/MasterTable/components/Search/NestedOption';
import ButtonWithSpinner from '../Utils/ButtonWithSpinner';
import history from '../../history';
import * as qs from 'qs';
import { allProducts, myProducts } from '../../assets/images/icons';
import KSicon from '../../assets/images/keurslager-icon.png';
import Souffrioicon from '../../assets/images/souffrio-icon.svg';
import ProductCounter from '../Table/components/ProductCounter';
import ProductTypeFilter from '../Table/components/Button/ProductTypeFilter';
import KeurslagerLabel from '../Table/components/Button/KeurslagerLabel';
import FiltersContainer from '../Pages/MasterTable/components/Filters/FiltersContainer';
import chevronSvg from '../../assets/images/chevron-icon.svg';
import { Chip } from 'mdbreact';
import { MultiSelect } from 'react-multi-select-component';
import DownloadAssortmentButton from '../Table/components/DownloadAssortmentButton';
import ToggleSwitch from '../Switch/Switch';
import SearchInput from './SearchForFilter';

class SearchWithFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            wasInitialized: false,
            prevSelected: [],
        };
    }

    fixLabel = (node) => {
        if (node && this.props.withSelect) {
            const label = node.querySelector(
                '.select-wrapper ul.select-dropdown input + label'
            );

            if (!label) {
                return;
            }

            label.textContent = this.props.intl.formatMessage({
                id: 'search.select.input_placeholder',
            });

            label.onclick = () => {
                label.classList.add('active');
            };
        }
    };

    getProductTypeFilterIcon(selectedType) {
        switch (selectedType) {
            case 1:
                return myProducts;
            case 2:
                return KSicon;
            default:
                return allProducts;
        }
    }

    getProductTypeFilterLabel(
        selectedType,
        productsCount,
        myProductsCount,
        myFavoriteProductsCount
    ) {
        switch (selectedType) {
            case 1:
                return this.props.intl.formatMessage(
                    { id: this.props.myListLabel },
                    { count: myProductsCount }
                );
            case 2:
                return this.props.intl.formatMessage({
                    id: 'table.keurslager_list.label',
                });
            case 3:
                return this.props.intl.formatMessage(
                    { id: 'table.favourite.label' },
                    { count: myFavoriteProductsCount }
                );
            case 4:
                return this.props.intl.formatMessage({
                    id: 'table.ma_supplier.label',
                });
            default:
                return this.props.intl.formatMessage(
                    { id: this.props.allProductsLabel },
                    { count: productsCount }
                );
        }
    }

    isChecked = (selectedValues, currentValue) => {
        if (!selectedValues) {
            return false;
        }
        return selectedValues.includes(currentValue);
    };

    arraysAreIdentical(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }

        arr1.sort();
        arr2.sort();

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        return true;
    }

    handleChangePromotion(toChange) {
        if (toChange === this.props.selectedPromotionType) {
            this.props.handlePromotionTypeSelect(0);
        } else {
            this.props.handlePromotionTypeSelect(toChange);
        }
    }

    getPromotionTypeFilterLabel(
        selectedType,
        countWithFilter,
        countWithoutFilter
    ) {
        switch (selectedType) {
            case 1:
                return this.props.intl.formatMessage(
                    { id: 'table.promotion_filter.with_promotion' },
                    { count: countWithFilter }
                );
            case 2:
                return this.props.intl.formatMessage(
                    { id: 'table.promotion_filter.without_promotion' },
                    { count: countWithoutFilter }
                );
            case 0:
            default:
                return this.props.intl.formatMessage({
                    id: 'table.promotion_filter.label',
                });
        }
    }

    render() {
        const {
            placeholder,
            loading,
            withSelect,
            categories,
            setSelectedCategory,
            selectedCategoryValue,
            productSelectedType,
            handleProductTypeSelect,
            productsCount,
            myProductsCount,
            myFavoriteProductsCount,
            showMyListButton,
            myListLabel,
            showOnlyMyList,
            allProductsLabel,
            showKSListButton,
            showMyFavourite,
            showMaSupplier,
            suppliers,
            selectedSupplierValue,
            setSelectedSupplier,
            setCommonSearch,
            searchHandler,
            commonSearchValue,
            withoutFilterCampaigns,
            withoutFilterAllergens,
            deleteSelectedSupplier,
            intl,
            handlerSelectedFilters,
            selectedPromotionType,
            handlePromotionTypeSelect,
            itemsWithPromotion,
            itemsWithoutPromotion,
            handleDownloadAssortment,
            handleDownloadAssortmentExcel,
            loadingDownloadAssortment,
            loadingDownloadAssortmentExcel,
            isFairActive,
        } = this.props;
        const queryParams = qs.parse(history.location.search.slice(1));

        let suppliersChecked;
        let options = [];

        if (suppliers?.length) {
            suppliersChecked = suppliers.map((supplier) => ({
                value: supplier.name,
                text: supplier.name,
                name: supplier.name,
                checked: this.isChecked(selectedSupplierValue, supplier.name),
            }));
            options = suppliers.map((supplier) => ({
                label: supplier.name,
                value: supplier.name,
            }));

            options.unshift({
                label: intl.formatMessage({
                    id: 'table.filters.input.supplier',
                }),
                value: 'all_suppliers',
            });

            options.sort((a, b) => {
                return Number(a.checked) - Number(b.checked);
            });
        }

        let categoriesOptions = [];
        if (categories?.length) {
            categories.forEach((category) => {
                if (category.name) {
                    categoriesOptions.push({
                        label: category.name,
                        value: category.name,
                        id: category.id,
                    });
                }
            });
        }

        let productTypeOptions = [];

        if (handleProductTypeSelect) {
            if (showMyListButton) {
                productTypeOptions.push({
                    label: `${intl.formatMessage(
                        { id: myListLabel },
                        { count: myProductsCount }
                    )}`,
                    value: 1,
                    text: intl.formatMessage(
                        { id: myListLabel },
                        { count: myProductsCount }
                    ),
                });
            }
            if (!showOnlyMyList) {
                productTypeOptions.push({
                    label: `${intl.formatMessage(
                        { id: allProductsLabel },
                        { count: productsCount }
                    )}`,
                    value: 0,
                    text: intl.formatMessage(
                        { id: allProductsLabel },
                        { count: productsCount }
                    ),
                });
            }
            if (showKSListButton) {
                productTypeOptions.push({
                    label: `${intl.formatMessage({
                        id: 'table.keurslager_list.label',
                    })}`,
                    value: 2,
                    icon: KSicon,
                    text: intl.formatMessage({
                        id: 'table.keurslager_list.label',
                    }),
                });
            }
            if (showMyFavourite) {
                productTypeOptions.push({
                    label: `${intl.formatMessage(
                        {
                            id: 'table.favourite.label',
                        },
                        { count: myFavoriteProductsCount }
                    )}`,
                    value: 3,
                    text: intl.formatMessage(
                        {
                            id: 'table.favourite.label',
                        },
                        { count: myFavoriteProductsCount }
                    ),
                });
            }
            if (
                showMaSupplier &&
                process.env.REACT_APP_ENABLE_MA_SUPPLIER_FILTER === 'true'
            ) {
                productTypeOptions.push({
                    label: `${intl.formatMessage({
                        id: 'table.ma_supplier.label',
                    })}`,
                    value: 4,
                    icon: Souffrioicon,
                    text: intl.formatMessage({
                        id: 'table.ma_supplier.label',
                    }),
                });
            }
        }

        return (
            <div className="main-search-filter-wrapper">
                <form id="main-search-filter" onSubmit={searchHandler}>
                    <div>
                        <div ref={this.fixLabel} className="search">
                            <SearchInput
                                onChange={setCommonSearch}
                                value={commonSearchValue}
                                type="text"
                                className="search__input search__input-whith-filters common-search-filter"
                                placeholder={placeholder}
                                currentSetSearch={queryParams['s_all']}
                            />
                        </div>
                        {!!categoriesOptions?.length && (
                            <div className="filters-title">
                                <p>Filters</p>
                                <div className="filters-line" />
                            </div>
                        )}
                        <div>
                            {!!categoriesOptions?.length && (
                                <MultiSelect
                                    options={categoriesOptions}
                                    labelledBy="Select"
                                    className="dropdown-multi mt-3 first"
                                    hasSelectAll={false}
                                    overrideStrings={{
                                        selectSomeItems: intl.formatMessage({
                                            id: 'search.select.option.all_categories',
                                        }),
                                        selectAllItems: intl.formatMessage({
                                            id: 'search.select.option.all_categories',
                                        }),
                                    }}
                                    value={selectedCategoryValue || []}
                                    onChange={setSelectedCategory}
                                />
                            )}
                        </div>
                        {handleProductTypeSelect && (
                            <div className="filter-divider" />
                        )}
                        <div>
                            {handleProductTypeSelect && (
                                <MultiSelect
                                    options={productTypeOptions}
                                    ItemRenderer={(item) => (
                                        <span
                                            onClick={() => {
                                                handleProductTypeSelect(
                                                    item?.option?.value
                                                );
                                            }}
                                        >
                                            {item?.option?.icon && (
                                                <img
                                                    src={item.option.icon ?? ''}
                                                    alt="icon"
                                                    className="keurslager-icon"
                                                />
                                            )}
                                            {item.option.label}
                                        </span>
                                    )}
                                    value={[
                                        {
                                            value: productSelectedType,
                                            label: this.getProductTypeFilterLabel(
                                                productSelectedType,
                                                productsCount,
                                                myProductsCount,
                                                myFavoriteProductsCount
                                            ),
                                        },
                                    ]}
                                    labelledBy="Select"
                                    className="dropdown-multi"
                                    hasSelectAll={false}
                                    disableSearch
                                    ClearSelectedIcon={() => <></>}
                                />
                            )}
                        </div>
                        {/* outdated, remove if switchers are good */}
                        {!withoutFilterCampaigns && false && (
                            <div>
                                <div className="filter-divider" />
                                <MultiSelect
                                    options={[
                                        {
                                            label: `${intl.formatMessage({
                                                id: 'table.promotion_filter.all',
                                            })}`,
                                            value: 0,
                                            text: intl.formatMessage({
                                                id: 'table.promotion_filter.all',
                                            }),
                                        },
                                        {
                                            label: `${intl.formatMessage(
                                                {
                                                    id: 'table.promotion_filter.with_promotion',
                                                },
                                                { count: itemsWithPromotion }
                                            )}`,
                                            value: 1,
                                            text: intl.formatMessage(
                                                {
                                                    id: 'table.promotion_filter.with_promotion',
                                                },
                                                { count: itemsWithPromotion }
                                            ),
                                        },
                                        {
                                            label: `${intl.formatMessage(
                                                {
                                                    id: 'table.promotion_filter.without_promotion',
                                                },
                                                { count: itemsWithoutPromotion }
                                            )}`,
                                            value: 2,
                                            text: intl.formatMessage(
                                                {
                                                    id: 'table.promotion_filter.without_promotion',
                                                },
                                                { count: itemsWithoutPromotion }
                                            ),
                                        },
                                    ]}
                                    ItemRenderer={(item) => (
                                        <span
                                            onClick={() => {
                                                handlePromotionTypeSelect(
                                                    item?.option?.value
                                                );
                                            }}
                                        >
                                            {item?.option?.icon && (
                                                <img
                                                    src={item.option.icon ?? ''}
                                                    alt="icon"
                                                    className="keurslager-icon"
                                                />
                                            )}
                                            {item.option.label}
                                        </span>
                                    )}
                                    value={[
                                        {
                                            value: selectedPromotionType,
                                            label: this.getPromotionTypeFilterLabel(
                                                selectedPromotionType,
                                                itemsWithPromotion,
                                                itemsWithoutPromotion
                                            ),
                                        },
                                    ]}
                                    labelledBy="Select"
                                    className="dropdown-multi"
                                    hasSelectAll={false}
                                    disableSearch
                                    ClearSelectedIcon={() => <></>}
                                />
                            </div>
                        )}
                        {!withoutFilterCampaigns && (
                            <div>
                                <div className="filter-divider" />
                                <FiltersContainer
                                    onlyFilters={['campaigns']}
                                    handlerSelectedFilters={
                                        handlerSelectedFilters
                                    }
                                />
                                {/* <MultiSelect
                                    className='dropdown-multi' options={options} value={selectedSupplierValue} onChange={setSelectedSupplier} labelledBy="Select" /> */}
                            </div>
                        )}
                        {!withoutFilterAllergens && (
                            <div>
                                <div className="filter-divider" />
                                <FiltersContainer
                                    onlyFilters={['allergens']}
                                    handlerSelectedFilters={
                                        handlerSelectedFilters
                                    }
                                />
                            </div>
                        )}
                        {suppliersChecked && <div className="filter-divider" />}

                        {suppliersChecked && (
                            <div className={`supplier-wrapper`}>
                                <>
                                    <MultiSelect
                                        options={options}
                                        ItemRenderer={(item) => {
                                            return (
                                                <div
                                                    className="supplier-item"
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        item.onClick();
                                                    }}
                                                >
                                                    <span>
                                                        {item.option.label}
                                                    </span>
                                                </div>
                                            );
                                        }}
                                        overrideStrings={{
                                            selectSomeItems: intl.formatMessage(
                                                {
                                                    id: 'table.filters.input.supplier',
                                                }
                                            ),
                                            search: intl.formatMessage({
                                                id: 'table.search.placeholder_lower',
                                            }),
                                        }}
                                        value={selectedSupplierValue}
                                        onChange={setSelectedSupplier}
                                        labelledBy="Select"
                                        className="dropdown-multi"
                                        hasSelectAll={false}
                                        id="select-suppliers"
                                        valueRenderer={() => (
                                            <span>
                                                {intl.formatMessage({
                                                    id: selectedSupplierValue.length
                                                        ? 'table.preferences.supplier'
                                                        : 'table.filters.input.supplier',
                                                })}
                                            </span>
                                        )}
                                    />
                                    {!!selectedSupplierValue?.length && (
                                        <div className={'suppliers_chips'}>
                                            {selectedSupplierValue?.map(
                                                (supplier) => {
                                                    return (
                                                        <Chip className="suppliers_chip">
                                                            <button
                                                                type="button"
                                                                data-id={
                                                                    supplier.value
                                                                }
                                                                data-type={
                                                                    supplier.value
                                                                }
                                                                data-name={
                                                                    supplier.value
                                                                }
                                                                className="filters__remove-filter chip_remove"
                                                                onClick={() =>
                                                                    deleteSelectedSupplier(
                                                                        supplier.value
                                                                    )
                                                                }
                                                            />
                                                            <span>
                                                                {supplier.value}
                                                            </span>
                                                        </Chip>
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                                </>
                            </div>
                        )}

                        {!withoutFilterCampaigns && (
                            <div className="promotion-switchers">
                                <div className="filter-divider bold" />
                                <ToggleSwitch
                                    value={selectedPromotionType === 1}
                                    onChange={() =>
                                        this.handleChangePromotion(1)
                                    }
                                    count={itemsWithPromotion}
                                    title={`${intl.formatMessage({
                                        id: 'table.promotion_filter.with_promotion_no_count',
                                    })}`}
                                />
                                <div className="filter-divider" />
                                <ToggleSwitch
                                    value={selectedPromotionType === 2}
                                    onChange={() =>
                                        this.handleChangePromotion(2)
                                    }
                                    count={itemsWithoutPromotion}
                                    title={`${intl.formatMessage({
                                        id: 'table.promotion_filter.without_promotion_no_count',
                                    })}`}
                                />
                                <div className="filter-divider bold" />
                            </div>
                        )}
                        {isFairActive && (
                            <div className="col m-0 pr-0">
                                <div className="row mr-0 alignHeader downloads-section">
                                    {handleProductTypeSelect &&
                                        showMyListButton && (
                                            <div className="productTypeHeader">
                                                <div className="download-lbl">
                                                    <FormattedMessage id="table.download_assortment.label" />
                                                </div>
                                                <div className="productTypeHeader__buttons-container">
                                                    <div className="download-btn">
                                                        <DownloadAssortmentButton
                                                            handleDownloadAssortment={
                                                                handleDownloadAssortment
                                                            }
                                                            downloadFormat="pdf"
                                                            loading={
                                                                loadingDownloadAssortment
                                                            }
                                                        />
                                                    </div>
                                                    <div className="download-btn">
                                                        <DownloadAssortmentButton
                                                            handleDownloadAssortment={
                                                                handleDownloadAssortmentExcel
                                                            }
                                                            downloadFormat="excel"
                                                            loading={
                                                                loadingDownloadAssortmentExcel
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        );
    }
}

SearchWithFilters.propTypes = {
    placeholder: PropTypes.string.isRequired,
};

export default injectIntl(SearchWithFilters);
