import { Spinner } from 'mdbreact';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageBanner from './components/Banner';
import { FormattedMessage } from 'react-intl';
import ProductBox from '../BoxProduct/components/ProducBox';

const BANNER_TYPE = 'banner';

const TopProduct = ({
    products,
    openProductDetails,
    toggleProductFavorite,
}) => {
    let separatorLineRef = useRef(null);
    let [scrollContainerWidth, setScrollContainerWidth] = useState(null);

    function updateSeparatorLineWidth() {
        // since InfiniteScroll doesnt take ref props, we will check this manually
        // assuming that: 1. this component has div and only two childs: scroll container and separatorline
        // 2. scroll container is always first
        // so we get the width of visual grid by subtracting coords of first and last elems in a row
        let productsListContainer =
            separatorLineRef?.current?.parentNode?.childNodes[0]
                ?.childNodes[0] ?? null;
        if (productsListContainer) {
            let leftCoordinateX =
                productsListContainer.getBoundingClientRect().x;
            let rightCoordinateX = leftCoordinateX;
            productsListContainer.childNodes.forEach((productBox) => {
                let rightCoordinateOfBox =
                    productBox.getBoundingClientRect().right;
                if (rightCoordinateOfBox > rightCoordinateX)
                    rightCoordinateX = rightCoordinateOfBox;
            });
            let productsGridWidth = rightCoordinateX - leftCoordinateX;
            setScrollContainerWidth(productsGridWidth);
        }
    }

    useEffect(() => {
        setTimeout(updateSeparatorLineWidth, 0);
    }, []);
    useEffect(() => {
        window.addEventListener('resize', updateSeparatorLineWidth);
        return () =>
            window.removeEventListener('resize', updateSeparatorLineWidth);
    }, []);

    return (
        <div>
            <div
                className="body-content-top-list-products-separator"
                ref={separatorLineRef}
            >
                <span className="text-content">
                    <FormattedMessage id="top_list.title" />
                </span>
            </div>

            <InfiniteScroll
                className="box-products top-products"
                hasMore={false}
                next={() => {}}
                dataLength={products.length}
                loader={
                    <div className="flex-center">
                        {' '}
                        <Spinner className="brand-color" />
                    </div>
                }
            >
                {products.map((product, index) => {
                    return product?.type === BANNER_TYPE ? (
                        <ImageBanner product={product} />
                    ) : (
                        <ProductBox
                            index={index}
                            key={product.id}
                            product={product}
                            openProductDetails={openProductDetails}
                            toggleProductFavorite={toggleProductFavorite}
                            isTopList={true}
                        />
                    );
                })}
            </InfiniteScroll>

            <div
                className="body-content-top-list-products-separator"
                ref={separatorLineRef}
            />
        </div>
    );
};

TopProduct.propTypes = {
    hasMore: PropTypes.bool,
    isLoading: PropTypes.any,
    loadMore: PropTypes.func,
    products: PropTypes.array,
    title: PropTypes.string,
    titleValues: PropTypes.object,
};

TopProduct.defaultProps = {
    title: 'menu.novelty',
    titleValues: {},
};

export default TopProduct;
