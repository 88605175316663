import PropTypes from 'prop-types';
import React from 'react';
import Search from '../../../Search/Search';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    fetchDelivers,
    fetchNoveltiesProducts,
    fetchPromotions,
} from '../../../../actions/routines';
class SearchContainer extends React.Component {
    intervalHandler = null;
    search = (params) => {
        if (this.props.type === 'promotions') {
            this.props.fetchPromotions(params);
        } else if (this.props.type === 'novelty') {
            this.props.fetchNoveltiesProducts({ params });
        } else {
            this.props.fetchDelivers(params);
        }
    };

    render() {
        const { intl, loading, isWithIcon, type } = this.props;

        return (
            <Search
                placeholder={intl.formatMessage({
                    id: 'delivery.search.placeholder',
                })}
                label={type}
                onSubmit={this.search}
                loading={loading}
                isWithIcon={isWithIcon || false}
            />
        );
    }
}

SearchContainer.propTypes = {
    intl: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        loading: state.delivers.loading,
    };
};

export default injectIntl(
    connect(mapStateToProps, {
        fetchDelivers,
        fetchPromotions,
        fetchNoveltiesProducts,
    })(SearchContainer)
);
