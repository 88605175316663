import React from 'react';
import {
    Navbar,
    NavbarNav,
    NavbarToggler,
    Collapse,
    NavItem,
    NavLink,
    Container,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Badge,
} from 'mdbreact';
import logo from '../../assets/images/huppa_logo.svg';
import {
    accountWhite,
    cartWhite,
    dashboardWhite,
    homeGrey,
    homeWhite,
    notificationWhite,
    allergensGrey,
    allergensWhite,
    campaignsGrey,
    campaignsWhite,
    recipesGrey,
    recipesWhite,
    downloadGrey,
    downloadWhite,
    deliveryGrey,
    deliveryWhite,
    promotionsGrey,
    promotionsWhite,
    exhibitionModuleGrey,
    exhibitionModuleWhite,
    tvIconWhite,
    tvIconGrey,
    pageGrey,
    pageWhite,
    newReleaseGrey,
    newReleaseWhite,
} from '../../assets/images/icons';
import CustomersDropdownMenu from '../CustomersDropdownMenu';
import LanguageSwitcherContainer from '../LanguageSwitcher/LanguageSwitcherContainer';
import DropdownMenuItem from '../Dropdown/DropdownMenuItem';
import FormattedMessageWithClass from '../Utils/FormattedMessageWithClass';
import getInformationsAboutActivePage from '../../services/activePageResolver';
import { logoutUser } from '../../actions/security';
import { connect } from 'react-redux';
import Spinner from '../Utils/Spinner';
import PropTypes from 'prop-types';
import UserService from '../../services/api/User';
import { fetchActiveCartData, fetchUserData } from '../../actions/routines';

class Header extends React.Component {
    constructor(props) {
        super(props);

        const { label, icon } = getInformationsAboutActivePage(
            this.props.history.location.pathname
        );
        this.state = {
            collapse: false,
            menuIcon: icon,
            menuLabel: label,
            currentCustomer: null,
        };
    }

    componentDidMount() {
        this.props.history.listen((location) => {
            const { label, icon } = getInformationsAboutActivePage(
                location.pathname
            );
            this.setState({
                menuIcon: icon,
                menuLabel: label,
            });
        });
    }

    onClick = () => {
        this.setState({
            collapse: !this.state.collapse,
        });
    };

    changeCustomerHandler = (customer) => {
        UserService.setCurrentCustomer(customer.id).then((response) => {
            this.setState({
                currentCustomer: customer,
            });

            if (this.props.userDetails) {
                this.props.fetchUserData();
            }

            this.props.fetchActiveCartData();
        });
    };

    render() {
        const {
            cartItemsCount,
            notificationsCount,
            userDetails,
            logout,
            locale,
            isCampaignActive,
            isAuthenticated,
        } = this.props;
        return (
            <Navbar expand="sm" fixed="top" className="homepage__navbar">
                <Container fluid="true">
                    <NavbarToggler onClick={this.onClick} />
                    <NavLink to="/dashboard">
                        <img
                            className="homepage__logo"
                            src={logo}
                            alt="logo"
                            height={38}
                        />
                    </NavLink>
                    <Collapse isOpen={this.state.collapse} navbar>
                        <NavbarNav left>
                            <NavItem>
                                <NavLink
                                    to="/dashboard"
                                    className="homepage__navbar__item"
                                >
                                    <img
                                        className="homepage__navbar__icon"
                                        src={dashboardWhite}
                                        alt="dashboard"
                                    />
                                </NavLink>
                            </NavItem>
                            {isAuthenticated && (
                                <NavItem>
                                    <Dropdown>
                                        <DropdownToggle nav caret>
                                            <img
                                                className="homepage__navbar__icon"
                                                src={this.state.menuIcon}
                                                alt="home"
                                            />
                                            <FormattedMessageWithClass
                                                className="homepage__navbar__label"
                                                id={this.state.menuLabel}
                                            />
                                        </DropdownToggle>
                                        <DropdownMenu className="homepage__navbar__dropdown">
                                            {!userDetails?.show_only_fair_module && (
                                                <>
                                                    <DropdownMenuItem
                                                        label="menu.order"
                                                        href="/products"
                                                        icon={homeWhite}
                                                        iconHover={homeWhite}
                                                    />
                                                    <DropdownMenuItem
                                                        label="menu.recipes"
                                                        href="/recipes/categories"
                                                        icon={recipesWhite}
                                                        iconHover={recipesWhite}
                                                    />
                                                    <DropdownMenuItem
                                                        label="menu.delivers"
                                                        href="/deliveries"
                                                        icon={deliveryWhite}
                                                        iconHover={
                                                            deliveryWhite
                                                        }
                                                    />
                                                    <DropdownMenuItem
                                                        label="menu.folders"
                                                        href={
                                                            locale === 'nl'
                                                                ? 'https://www.huppa.be/category/folders/'
                                                                : 'https://www.huppa.be/fr/category/brochures/'
                                                        }
                                                        isExternal
                                                        icon={pageWhite}
                                                        iconHover={pageWhite}
                                                    />
                                                    <DropdownMenuItem
                                                        label="menu.promotions"
                                                        href="/promotions"
                                                        icon={promotionsWhite}
                                                        iconHover={
                                                            promotionsWhite
                                                        }
                                                    />
                                                    <DropdownMenuItem
                                                        label="menu.allergens"
                                                        href="/allergens"
                                                        icon={allergensWhite}
                                                        iconHover={
                                                            allergensWhite
                                                        }
                                                    />
                                                    <DropdownMenuItem
                                                        label="menu.novelty"
                                                        href="/novelty"
                                                        icon={newReleaseWhite}
                                                        iconHover={
                                                            newReleaseWhite
                                                        }
                                                    />
                                                    <DropdownMenuItem
                                                        label="menu.downloads"
                                                        href="/downloads"
                                                        icon={downloadWhite}
                                                        iconHover={
                                                            downloadWhite
                                                        }
                                                    />
                                                    {isCampaignActive && (
                                                        <DropdownMenuItem
                                                            label="menu.campaigns"
                                                            href="/campaign"
                                                            icon={
                                                                campaignsWhite
                                                            }
                                                            iconHover={
                                                                campaignsWhite
                                                            }
                                                        />
                                                    )}
                                                </>
                                            )}
                                            {/*@TODO F20-110 LEAVE FOR NOW*/}
                                            {/*<DropdownMenuItem label="menu.tv_brochure" href="#" icon={tvBrochureGrey} iconHover={tvBrochureWhite} />*/}
                                            {userDetails &&
                                                userDetails.is_fair_active && (
                                                    <DropdownMenuItem
                                                        label="menu.exhibition_module"
                                                        href="/fair"
                                                        icon={
                                                            exhibitionModuleWhite
                                                        }
                                                        iconHover={
                                                            exhibitionModuleWhite
                                                        }
                                                    />
                                                )}
                                        </DropdownMenu>
                                    </Dropdown>
                                </NavItem>
                            )}
                            {isAuthenticated && (
                                <NavItem>
                                    <NavLink to="/cart">
                                        <img
                                            className="homepage__navbar__icon"
                                            src={cartWhite}
                                            alt="cart"
                                        />
                                        <FormattedMessageWithClass
                                            className="homepage__navbar__label"
                                            id={'navbar.shopping_cart'}
                                        />
                                        {cartItemsCount > 0 && (
                                            <Badge className="badge--circle badge--white">
                                                {cartItemsCount}
                                            </Badge>
                                        )}
                                    </NavLink>
                                </NavItem>
                            )}
                            {isAuthenticated && (
                                <NavItem>
                                    <NavLink to="/notifications">
                                        <img
                                            className="homepage__navbar__icon"
                                            src={notificationWhite}
                                            alt="notifications"
                                        />
                                        <FormattedMessageWithClass
                                            className="homepage__navbar__label"
                                            id={'navbar.notifications'}
                                        />
                                        {notificationsCount > 0 && (
                                            <Badge className="badge--circle badge--red">
                                                {notificationsCount}
                                            </Badge>
                                        )}
                                    </NavLink>
                                </NavItem>
                            )}
                        </NavbarNav>
                        <NavbarNav right>
                            {isAuthenticated && (
                                <NavItem className="nav-account">
                                    <Dropdown className="account-dropdown-menu">
                                        <DropdownToggle nav caret>
                                            <img
                                                className="homepage__navbar__icon"
                                                src={accountWhite}
                                                alt="account"
                                            />
                                            <span className="homepage__navbar__label">
                                                {!userDetails ? (
                                                    <Spinner
                                                        width={15}
                                                        height={15}
                                                        className="spinner--white"
                                                    />
                                                ) : (
                                                    userDetails
                                                        ?.current_customer
                                                        .username
                                                )}
                                            </span>
                                        </DropdownToggle>
                                        {userDetails && (
                                            <CustomersDropdownMenu
                                                logout={logout}
                                                customers={
                                                    userDetails.customers
                                                }
                                                changeCustomerHandler={
                                                    this.changeCustomerHandler
                                                }
                                                selected={
                                                    this.state.currentCustomer
                                                        ?.id ||
                                                    userDetails.current_customer
                                                        .id
                                                }
                                            />
                                        )}
                                    </Dropdown>
                                </NavItem>
                            )}
                            <LanguageSwitcherContainer variant="header" />
                        </NavbarNav>
                    </Collapse>
                </Container>
            </Navbar>
        );
    }
}

Header.propTypes = {
    isAuthenticated: PropTypes.bool,
    fetchActiveCartData: PropTypes.func,
    fetchUserData: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        cartItemsCount: state.cart.total,
        notificationsCount: state.notifications.total,
        userDetails: state.userDetails.data,
        locale: state.locale.lang,
        isCampaignActive: state.campaigns.isCampaignActive,
        isAuthenticated: !!state.security.access_token,
    };
};

export default connect(mapStateToProps, {
    logout: logoutUser,
    fetchActiveCartData: fetchActiveCartData,
    fetchUserData: fetchUserData,
})(Header);
