import PropTypes from 'prop-types';
import React from 'react';
import ItemBox from '../../components/ItemBox/ItemBox';
import TitleHeader from '../../components/TitleHeader';
import { recipesGrey } from '../../../../assets/images/icons';
import { FormattedMessage } from 'react-intl';
import SearchContainer from '../components/SearchContainer';
import { connect } from 'react-redux';
import { fetchRecipeCategories } from '../../../../actions/routines';
const RecipesCategories = ({ categories, fetchRecipeCategories }) => {
    const searchCategories = (params) => {
        fetchRecipeCategories(params);
    };
    return (
        <div className="recipes recipes__categories">
            <TitleHeader
                icon={recipesGrey}
                title="recipes.categories.title"
                className="left-align-header"
            >
                <SearchContainer
                    isWithIcon={true}
                    searchCategories={searchCategories}
                />
                {/* <p className="mt-4">
                        <Link to="/recipes/favourites" className="recipes__list__favourites">
                            <i className="favourite-icon__icon favourite-icon--is-favourite"/>
                            <FormattedMessage id="recipes.favourites_url"/>
                        </Link>
                    </p> */}
            </TitleHeader>
            <div className="recipes__categories__container">
                {categories.map((category) => (
                    <ItemBox
                        key={category.id}
                        image={category.image}
                        title={category.title}
                        text={
                            <FormattedMessage
                                id="recipes.categories.recipesNumber"
                                values={{ number: category.recipes_count }}
                            />
                        }
                        link={`/recipes/${category.slug}`}
                    />
                ))}
            </div>
        </div>
    );
};

RecipesCategories.propTypes = {
    categories: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => {
    return {
        loading: state.recipeCategories.loading,
    };
};
export default connect(mapStateToProps, { fetchRecipeCategories })(
    RecipesCategories
);
