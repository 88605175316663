import React from 'react';
import { FormattedMessage } from 'react-intl';
import QuantityContainer from '../../components/Quantity/QuantityContainer';
import PopoverBadge from '../../components/Table/components/PopoverBadge';
import moment from 'moment';
import CampaignPointsBadgeContainer from '../../components/Table/components/CampaignPointsBadge/CampaignPointsBadgeContainer';
import ProductPhoto from '../../components/Table/components/ProductPhoto';
import FavouriteIcon from '../../components/Favourite/FavouriteIcon';

const getColumns = () => {
    return [
        {
            id: 'productPhoto',
            Header: <FormattedMessage id="table.header.product_photo" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.product_photo" />
            ),
            filterable: false,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__product-photo disable-sort-indicator column-header-left',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__product-photo disable-sort-indicator',
            show: true,
            width: 150,
            sortable: false,
            showInPreferences: false,
            Cell: (row) => {
                return <ProductPhoto product={row.original} />;
            },
        },
        {
            id: 'favourite',
            Header: '',
            preferencesLabel: '',
            accessor: 'favourite',
            filterable: false,
            width: 35,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__favourite disable-sort-indicator column-header-left',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__favourite disable-sort-indicator',
            show: true,
            sortable: false,
            Cell: (row) => {
                return (
                    <FavouriteIcon
                        type="product"
                        id={row.original.id}
                        onClick={() => {}}
                        isFavourite={row.original.isFavourite}
                    />
                );
            },
        },
        {
            id: 'article_nr',
            Header: <FormattedMessage id="table.header.product_no" />,
            preferencesLabel: <FormattedMessage id="table.preferences.id" />,
            filterPlaceholder: 'table.filters.input.article_nr',
            accessor: 'article_nr',
            width: 85,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__id column-header-left',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__id bold',
            show: true,
            Cell: (row) =>
                row.value ? row.value.toString().padStart(7, '0') : '',
        },
        {
            id: 'name',
            Header: <FormattedMessage id="table.header.product_name" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.product_name" />
            ),
            filterPlaceholder: 'table.filters.input.product_name',
            accessor: 'name',
            width: 350,
            style: { overflow: 'visible', whiteSpace: 'normal' },
            headerClassName:
                'table__rt__col table__rt__product-name column-header-left',
            className: 'table__rt__col table__rt__product-name bold',
            show: true,
            Cell: (row) => {
                return (
                    <div>
                        {row.value}
                        {row.original.isNoAvailableRefund && (
                            <div className="product-novelty__item-box__no-refund-small-title column-header-left">
                                <FormattedMessage id={'product.no_refund'} />
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            id: 'supplier',
            Header: <FormattedMessage id="table.header.supplier" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.supplier" />
            ),
            filterPlaceholder: 'table.filters.input.supplier',
            accessor: 'supplier',
            headerClassName:
                'table__rt__col table__rt__supplier column-header-left',
            className: 'table__rt__col table__rt__supplier',
            show: true,
            width: 'auto',
        },

        {
            id: 'availableFrom',
            Header: <FormattedMessage id="table.header.available_from" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.available_from" />
            ),
            accessor: 'availableFrom',
            width: 90,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__available_from disable-sort-indicator column-header-left',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__available_from disable-sort-indicator',
            show: true,
            filterable: false,
            sortable: false,
            Cell: (row) => {
                return row.original.promotions.map((promotion) => (
                    <p>{moment(promotion.start_date).format('DD/MM/YYYY')}</p>
                ));
            },
        },
        {
            id: 'availableTo',
            Header: <FormattedMessage id="table.header.available_to" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.available_to" />
            ),
            accessor: 'availableTo',
            width: 90,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__available_to disable-sort-indicator column-header-left',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__available_to disable-sort-indicator',
            show: true,
            filterable: false,
            sortable: false,
            Cell: (row) => {
                return row.original.promotions.map((promotion) => (
                    <p>{moment(promotion.end_date).format('DD/MM/YYYY')}</p>
                ));
            },
        },
        {
            id: 'promotion',
            Header: <FormattedMessage id="table.header.promotion" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.promotion" />
            ),
            accessor: 'promotion',
            filterable: false,
            sortable: false,
            width: 55,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__promotion disable-sort-indicator column-header-left',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__promotion disable-sort-indicator',
            show: true,
            Cell: (row) => {
                return row.original.promotions.map((promotion) => (
                    <PopoverBadge
                        id={row.original.id}
                        type="promotion"
                        badgeText={`i`}
                        backgroundColor={
                            promotion.is_future
                                ? 'grey'
                                : promotion.promotion_type === 1
                                ? '#FFDE00'
                                : '#D70000'
                        }
                        fontColor={
                            promotion.promotion_type === 1
                                ? 'darkblue'
                                : 'white'
                        }
                        popoverHeader={promotion.promotion}
                        popoverBody={`
                            ${promotion.description}
                            <br> <small>${moment(promotion.start_date).format(
                                'DD/MM/YYYY'
                            )} - ${moment(promotion.end_date).format(
                            'DD/MM/YYYY'
                        )}</small>
                        `}
                    />
                ));
            },
        },
        {
            id: 'quantity',
            Header: <FormattedMessage id="table.header.quantity" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.quantity" />
            ),
            accessor: 'quantity',
            filterable: false,
            sortable: false,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__quantity disable-sort-indicator',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__quantity disable-sort-indicator',
            show: true,
            width: 'auto',
            Cell: (row) => {
                return row.original.promotions.map((promotion) => {
                    return (
                        <QuantityContainer
                            product={row.original}
                            productId={row.original.id}
                            quantity={
                                row.original.amount ? row.original.amount : 0
                            }
                            tabIndex={row.viewIndex + 1}
                            itemsInPackage={row.original.items_in_package}
                            orderAsPackage={row.original.order_as_package}
                            showCount
                            isProductsList
                        />
                    );
                });
            },
        },
        {
            id: 'points',
            Header: <FormattedMessage id="table.header.points" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.points" />
            ),
            accessor: (d) => (d.campaign ? d.campaign.points : d.id),
            filterable: false,
            sortable: false,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__campaign disable-sort-indicator column-header-left',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__campaign disable-sort-indicator',
            width: 'auto',
            show: true,
            Cell: (row) => {
                if (row.original.campaigns) {
                    return row.original.campaigns.map((campaign) => {
                        return (
                            <CampaignPointsBadgeContainer
                                key={campaign.id}
                                productId={row.original.id}
                                campaign={campaign}
                            />
                        );
                    });
                }
            },
        },
    ];
};

export default getColumns;
